<template>
    <div class="d-flex">
        <div v-for="(data, indx) in pedidos" :key="`group-${indx}`" class="">
            <div class="mx-2 h-100">
                <div class="border-general2 my-2 text-general2 f-600 f-14 br-5 py-2 position-relative text-center">
                    {{ formatearDate(data.fecha) }}
                </div>
                <div class="d-flex h-100">
                    <div v-for="(horario, idx) in data.horarios" :key="idx" class="width-column">
                        <div class="mt-2 mx-0 content-column px-2">
                            <!-- Rango de Hora -->
                            <div class="col-12 mx-0 bg-light-f5 br-5 py-2 text-center tres-puntos cr-pointer" @click="asignarEntrega(data)">
                                <span class="text-general2 f-600">
                                    {{ formatDate(`${data.fecha} ${horario.horario}:00`) }} - {{ formatDate(`${data.fecha} ${horario.fin}:00`) }}
                                </span>
                                <span class="bg-white border border-general p-2 rounded-circle m-2">{{ horario.pedidos.length }}</span>
                            </div>
                            <draggable
                            :list="horario.pedidos"
                            draggable=".item"
                            class="list-group"
                            :group="{name: 'a', put: fechaEsMayorOIgual(data.fecha)}"
                            :empty-insert-threshold="300"
                            :options="{disabled : !$can('botones_abastecimiento_tablero_horario')}"
                            @end="checkMove"
                            @change="updateFecha"
                            >
                                <!-- Estados -->
                                <div
                                v-for="(pedidoI ,c) in horario.pedidos"
                                :id="`${data.fecha}:${horario.horario}`"
                                :key="c"
                                :class="pedido.estado != 3 ? 'item' : ''"
                                >
                                    <el-popover
                                    placement="bottom"
                                    trigger="hover"
                                    :open-delay="500"
                                    @show="onShow(pedidoI)"
                                    >
                                        <div class="row mx-0">
                                            <div class="col-12">
                                                <p class="text-general2 f-15 f-600">
                                                    Cedis: {{ _.get(pedido, 'cedis_nombre', 'Sin nombre') }}
                                                </p>
                                                <p class="text-general2 f-15 f-600">
                                                    Valor: {{ convertMoney(_.get(pedido, 'valor_final', '0'), _.get(pedido, 'idm_moneda', 1)) }}
                                                </p>
                                                <p class="text-general2 f-15 mt-3">
                                                    {{$config.vendedor}}: {{ _.get(pedido, 'tienda_nombre', 'Sin nombre') }}
                                                </p>
                                                <p v-if="_.get(pedido, 'cliente_entrega_directa', false)" class="text-general2 f-15">
                                                    Cliente: {{ _.get(pedido, 'nombre_cliente', 'Sin nombre') }}
                                                </p>
                                                <p class="text-general2 f-15">
                                                    Teléfono: {{ _.get(pedido, 'telefono', 'No registra') }}
                                                </p>
                                                <p class="text-general2 f-15">
                                                    Dirección: {{ _.get(pedido, 'direccion', 'Sin dirección') }}
                                                </p>
                                                <p v-if="_.get(pedido, 'id_delivery', false)" class="text-general2 f-15 mt-3">
                                                    Delivery: {{ _.get(pedido, 'delivery_nombre', 'Sin nombre') }}
                                                </p>
                                            </div>
                                        </div>
                                        <div slot="reference">
                                            <div class="br-5 px-2 py-2 text-white tres-puntos mt-2 mb-1 text-center cr-pointer" :class="estadoPedido(pedidoI.estado)" @click="goTo(pedidoI)">
                                                <i :class="iconoPedido(pedidoI.estado)" />
                                                <b> {{ pedidoI.id }}</b>
                                            </div>
                                        </div>
                                    </el-popover>
                                    <div v-if="verCedis" class="row mx-0 justify-content-between">
                                        <span class="f-14" v-text="pedidoI.cedis_nombre" /> <i v-show="pedidoI.id_user_delivery" class="icon-truck text-general f-17" />
                                    </div>
                                </div>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-asignacion ref="modalAsignacion" @listar="$emit('listar')" />
    </div>
</template>

<script>
import moment from 'moment'
import tablero from '~/services/pedidos/tablero'
const estados = {
    0: { color: 'nothing', icon: 'nothing' },
    1: { color: 'bg-yellow', icon: 'icon-receipt f-20' },
    2: { color: 'bg-blue', icon: 'icon-ok-circled f-20' },
    201: { color: 'bg-pink', icon: 'icon-cart-arrow-down f-20' },
    202: { color: 'bg-purple', icon: 'icon-shopping-outline f-20' },
    3: { color: 'bg-green', icon: 'icon-truck f-20' },
}
export default {
    components: {
        modalAsignacion: () => import('../partials/modalAsignacion')
    },
    props: {
        verCedis: {
            type: Boolean,
            default: () => false
        },
        pedidos: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            toFecha: '',
            pedido: {}
        }
    },
    methods: {
        estadoPedido: (state = null) =>  state ? estados[state].color : estados[0].color,
        iconoPedido: (state = null) =>  state ? estados[state].icon : estados[0].icon,
        formatDate: (fecha) => moment(fecha).format('h A'),
        fechaEsMayorOIgual: (fecha) => moment(fecha).isSameOrAfter(moment().format('YYYY-MM-DD')),
        formatearDate(fecha){
            return moment(fecha).isSame(moment().format('YYYY-MM-DD')) ? 'Hoy' : (moment(fecha).isSame(moment().add(1, 'day').format('YYYY-MM-DD')) ? 'Mañana' : this.formatearFecha(fecha))
        },
        updateFecha(event){
            if(event.added){
                setTimeout(async() => {
                    if (this.toFecha){
                        let fechaSplit = this.toFecha.split(':')
                        const data = {
                            fecha: fechaSplit[0],
                            hora: fechaSplit[1],
                            id_pedido: event.added.element.id
                        }
                        await tablero.updateFranja(data)
                    }
                }, 500);
            }
            // console.log(event);
        },
        checkMove(evt){
            this.toFecha = _.get(evt, 'to.firstChild.id', '')
        },
        async onShow({ id }){
            try {
                const { data } = await tablero.showPedido(id)
                this.pedido = data
            } catch (error){
                this.error_catch(error)
            }
        },
        goTo({ id: id_pedido }){
            let route = this.$router.resolve({ name: 'admin.almacen.pedidos', params: { id_pedido } })
            window.open(route.href, '_blank');
        },
        asignarEntrega(data){
            const tienePedidos = data.horarios.some(el => !!(el.pedidos.length))
            if(!tienePedidos) return
            this.$refs.modalAsignacion.toggle(data);
        }

    }
}
</script>

<style lang="scss" scoped>
.width-column{
    width: 200px;
}
.content-column{
    /* border-left: 2px solid var(--color-db); */
    border-right: 2px solid var(--color-db);
}
/* .circle{
    width: 15px;
    position: absolute;
    height: 15px;
    top: -4px;
    right: -5px;
    background-color: var(--color-general);
    border-radius: 50%;
} */
.bg-yellow{ background-color: #FF9D32; }
.text-yellow{ color: #FF9D32; }
.bg-blue{ background-color: #0892DD; }
.text-blue{ color: #0892DD;}
.bg-pink{ background-color: #D6017B; }
.text-pink{ color: #D6017B;}
.bg-purple{ background-color: #6D3CE1; }
.text-purple{ color: #6D3CE1; }
.bg-green{ background-color: #28D07B;}
.text-green{ color: #28D07B; }
</style>
